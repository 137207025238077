export const langObj = {
    quote: {
        "hu": "'Az öltözködés felfokozottság, meglepetés és fantázia elegye, nem a szabályoké.'",
        "en": "'Fashion is about suspense, surprise and fantasy. It's not about rules.'"
    },

    imageHolderOne: {
        "hu": "AZ ESKA-NÁL MEGGYŐZŐDÉSÜNK, HOGY A DIVAT TÖBB MINT A JÓ KINÉZET. OLYAN DARABOKAT KÍNÁLUNK, AMIK DIVATOSAK ÉS PRAKTIKUSAK IS EGYBEN.",
        "en": "AT ESKA WE BELIEVE THAT FASHION IS MORE ABOUT THAN JUST LOOKING GOOD. WE PROVIDE OUR CUSTOMERS SETS, THAT ARE BOTH PRACTICAL AND FASHIONABLE."
    },

    fulfill: {
        "hu": "Ha személyre szabott igénye van mérettel vagy szabással kapcsolatban, lépjen kapcsolatba velünk",
        "en": "If you have specific wishes regarding size or cut, we'll be at your service"
    },

    contactButton: {
        "hu": "KAPCSOLAT",
        "en": "CONTACT US"
    }
}