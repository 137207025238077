import React, { useState } from "react";
import "./Card.css";
import { useSpring, animated } from "@react-spring/web";
import { Button, Typography } from "@mui/material";
import { useThemeContext } from "../../../contprovider/themeProvider";
import { useLangContext } from "../../../contprovider/langProvider";
import { langObj } from "../../../languages/Contact";

function Card({ image, title, button, text }) {
    let anchor;
    if(title["en"] === "Visit us") {
        anchor = "https://maps.app.goo.gl/5dfeznnpy1FzEayV9";
    } else if(title["en"] === "Visit our other store") {
        anchor = "https://maps.app.goo.gl/s2cqoXptTJGPg88f6";
    } else {
        anchor = "tel:+36703612875";
    }

    const isCallCard = title["en"] === "Call us";
    const [highlight, setHighlight] = useState(false);
    const { isDarkTheme } = useThemeContext();
    const { currentLang } = useLangContext();

    const styleConf = useSpring({
        opacity: 1,
        transform: highlight ? "scale(1.03)" : "scale(1)",
        boxShadow: highlight
          ? "0 20px 25px rgb(0 0 0 / 25%)"
          : "0 2px 10px rgb(0 0 0 / 8%)"
    });

    return (
        <>
        <div
        id="contact-card-container"
        >
            <animated.div 
            onMouseEnter={() => setHighlight(true)}
            onMouseLeave={() => setHighlight(false)}
            onTouchStart={() => setHighlight(true)}
            onTouchEnd={() => setHighlight(false)}
            style={styleConf}
            id="contact-card-inner-container"
            >
                <img src={image} alt="contact logo"/>
                <Typography 
                variant="h5" 
                id={`contact-card-title`}
                className={isCallCard ? "call-card" : ""}
                >
                    {title[currentLang]}
                    
                </Typography>
                <Typography variant="body1" id="contact-card-text">
                    {text[currentLang]}
                </Typography>
                <div className="contact-card-button-holder">
                    <Button variant="contained" id="contact-button">
                        <a 
                        href={anchor} 
                        target="_blank" 
                        className="slider-anchor"
                        >
                            {button[currentLang]}
                        </a>
                    </Button>
                </div>
            </animated.div>
        </div>
        <style jsx>
            {`
            #contact-card-inner-container {
                background-color: ${isDarkTheme ? "darkgrey" : "antiquewhite"};
            }
            #contact-card-text {
                color: ${isDarkTheme ? "white" : "black"};
            }
            #contact-card-title {
                color: ${isDarkTheme ? "white" : "brown"};
            }
            .contact-card-button-holder #contact-button {
                background-color: ${isDarkTheme ? "rgba(255, 215, 0, 1)": "brown"};
                color: ${isDarkTheme ? "black" : "white"};
            }
            #contact-card-inner-container .slider-anchor {
                color: ${isDarkTheme ? "black" : "white"};
                text-decoration: none;
            }
            `}
        </style>
        </>
    )

};

export default Card;
