import React, { useState, useEffect } from "react";
/// Import styles
import "./ExpiredSeason.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// import "yet-another-react-lightbox/plugins/counter.css";
///
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// import Counter from "yet-another-react-lightbox/plugins/counter";
import { NavLink } from "react-router-dom";
import {   
    Box, 
    AppBar, 
    Toolbar, 
    IconButton, 
    Drawer,
    List,
    ListItem,
    Typography,
    ListItemButton,
    Grow,
  
    Button
}
from "@mui/material";
import Fade from '@mui/material/Fade';
/// Context Providers
import { useOpenContext } from "../../../contprovider/openProvider";
import { useThemeContext } from "../../../contprovider/themeProvider";
import { useLangContext } from "../../../contprovider/langProvider";
import useScrollPosition from "../../../hooks/useScrollPosition";
///
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function ExpiredSeason({ images, season, next, prev }) {

    const { isDarkTheme } = useThemeContext();
    const { open } = useOpenContext();
    const { currentLang } = useLangContext();
    const scrollYPos = useScrollPosition();

    const isInNullPosition = scrollYPos >= 170 && scrollYPos < 1000;

    const [hoveredOverIdx, setHoveredOverIdx] = useState(null);

    const [selectedIdx, setSelectedIdx] = useState(-1);

    const handleMouseEnter = (idx) => {
        setHoveredOverIdx(idx);
    };
    
    const handleMouseLeave = () => {
        setHoveredOverIdx(null);
    };

    const slides = images.map((pic, idx) => {
        return {
            src: pic,
            width: 1260,
            height: 1900,
            index: idx
        }
    });

    /////
    const boxVariant1 = {
        visible: { opacity: 1, transition: {staggerChildren: 1, duration: 1}, },
        hidden: { opacity: 0, }
    };

    const boxVariant2 = {
        visible: { opacity: 1, transition: {staggerChildren: 1, duration: 0.4}, },
        hidden: { opacity: 0, }
    };

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        control.start("visible");
        
    })
    /////


    const control2 = useAnimation();
    const [ref2, inView2] = useInView();

    useEffect(() => {
        control2.start("visible");
    });

    /////

    useEffect(() => {
        console.log(`ScrollYpos changes isAtNullPos to: ${isInNullPosition}`);
    }, [scrollYPos]);

    return (
        <motion.div 
        initial="hidden"
        animate={control}
        ref={ref}
        variants={boxVariant1}
        className={`legacy-fall-container ${open ? "legacy-fall-container-blurred" : ""}`}
        >
          
            <div className="legacy-fall-inner-container">
                <div
                className={`legacy-header`}
                >
                    
                    <div 
                    className={`legacy-inner-header`}
                    >

                        <Button
                        variant="contained"
                        id="return-button"
                        style={{
                            border: `1px solid ${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,
                            backgroundColor: `${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,
                            
                        }}
                        >
                            <NavLink 
                            to="/collections"
                            id="return-navlink"
                            style={{
                                color: `${isDarkTheme ? "black" : "white"}`
                            }}
                            >   
                                {currentLang === "hu" ? "Vissza" : "Back"}
                            </NavLink>
                        </Button>
                        <Typography
                        id="season-type"
                        variant="h2"
                        style={{
                            color: `${isDarkTheme ? "var(--black-theme-font)" : "black"}`, 
                        }}
                        >
                            {season[currentLang]}
                        </Typography>
                    </div>
                    <div className="legacy-inner-header second-inner">
                        <Button
                        id="nav-button"
                        variant="outlined"
                        style={{
                            border: `1px solid ${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,

                        }}
                        {...(!prev ? { disabled: true, } : {  })}
                        >
                            <NavLink
                            id="directional-navlink"
                            style={{
                                color: `${isDarkTheme ? "var(--black-theme-font)" : "black"}`
                            }}
                            to={prev || "#"}
                            >
                                {currentLang === "hu" ? "Előző" : "Prev."}
                            </NavLink>
                        </Button>
                        <Button
                        id="nav-button"
                        className="next-button"
                        variant="contained"
                        style={{
                            backgroundColor: `${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,

                        }}
                        {...(!next ? { disabled: true } : {})}
                        >
                            <NavLink
                            id="directional-navlink"
                            style={{
                                color: `${isDarkTheme ? "black" : "white"}`
                            }}
                            to={next || "#"}
                            >
                                {currentLang === "hu" ? "Tovább" : "Next"}
                                
                            </NavLink>
                        </Button>
                    </div>
                    <div className="legacy-inner-header third-inner">
                        <Typography
                            id="season-type-mobile"
                            variant="h2"
                            style={{
                                color: `${isDarkTheme ? "var(--black-theme-font)" : "black"}`, 
                            }}
                            >
                                {season[currentLang]}
                        </Typography>
                    </div>
                    
                </div>
                {isInNullPosition && (
                    <motion.div
                    initial="hidden"
                    animate={control2}
                    ref={ref2}
                    variants={boxVariant2}
                    style={{
                        backgroundColor: isDarkTheme ? "#4d4d4d" : "white"
                    }}
                    className={`legacy-header ${isInNullPosition ? "legacy-header-not-at-null" : ""}`}
                    >
                         
                        <div 
                        className={`legacy-inner-header ${isInNullPosition ? "" : ""}`}
                        style={{
                            backgroundColor: isDarkTheme ? "#4d4d4d" : "white"
                        }}
                        >
     
                            <Button
                            variant="contained"
                            id="return-button"
                            style={{
                                border: `1px solid ${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,
                                backgroundColor: `${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,
                                 
                            }}
                            >
                                <NavLink 
                                to="/collections"
                                id="return-navlink"
                                style={{
                                    color: `${isDarkTheme ? "black" : "white"}`
                                }}
                                >   
                                    {currentLang === "hu" ? "Vissza" : "Back"}
                                </NavLink>
                            </Button>
                            <Typography
                            id="season-type"
                            variant="h2"
                            style={{
                                color: `${isDarkTheme ? "var(--black-theme-font)" : "black"}`, 
                            }}
                            >
                                {season[currentLang]}
                            </Typography>
                        </div>
                        <div 
                        className="legacy-inner-header second-inner"
                        style={{
                            backgroundColor: isDarkTheme ? "#4d4d4d" : "white"
                        }}
                        >
                            <Button
                            id="nav-button"
                            variant="outlined"
                            style={{
                                border: `1px solid ${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,
     
                            }}
                            {...(!prev ? { disabled: true, } : {  })}
                            >
                                <NavLink
                                id="directional-navlink"
                                style={{
                                    color: `${isDarkTheme ? "var(--black-theme-font)" : "black"}`
                                }}
                                to={prev || "#"}
                                >
                                    {currentLang === "hu" ? "Előző" : "Prev."}
                                </NavLink>
                            </Button>
                            <Button
                            id="nav-button"
                            className="next-button"
                            variant="contained"
                            style={{
                                backgroundColor: `${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"}`,
     
                            }}
                            {...(!next ? { disabled: true } : {})}
                            >
                                <NavLink
                                id="directional-navlink"
                                style={{
                                    color: `${isDarkTheme ? "#4d4d4d" : "white"}`
                                }}
                                to={next || "#"}
                                >
                                    {currentLang === "hu" ? "Tovább" : "Next"}
                                     
                                </NavLink>
                            </Button>
                        </div>
                        <div className="legacy-inner-header third-inner">
                            <Typography
                                id="season-type-mobile"
                                variant="h2"
                                style={{
                                    color: `${isDarkTheme ? "var(--black-theme-font)" : "black"}`, 
                                }}
                                >
                                    {season[currentLang]}
                            </Typography>
                        </div>
                         
                    </motion.div>
                )}
                <PhotoAlbum 
                layout="columns"
                photos={slides}
                spacing={1}
                renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (

                    <div
                    className="overlay-container" 
                    style={wrapperStyle}
                    onMouseEnter={() => handleMouseEnter(photo.index)}
                    onMouseLeave={() => handleMouseLeave()}
                    >
                        {renderDefaultPhoto({ wrapped: true })}
                        {hoveredOverIdx === photo.index && (
                            <div 
                            className="hovered-container"
                            >
                                {photo.index+1}
                            </div>
                        )}
                    </div>
                )}
                onClick={({ index: current }) => setSelectedIdx(current)}
                />

                <Lightbox 
                index={selectedIdx}
                slides={slides}
                open={selectedIdx >= 0}
                close={() => setSelectedIdx(-1)}
                plugins={[Thumbnails]}
                counter={{ container: { style: {  top: 0, right: 0 } } }}
                style={{
                    root: { "--yarl__color_backdrop": "rgba(0, 0, 0, 0)" }
                }}
                thumbnails={{
                    position: "bottom",
                    width: 50,
                    gap: 0,
                    border: 0,
                    borderRadius: 15
                    
                }}
                />
            </div>
        </motion.div>
    )
};

export default ExpiredSeason;