import React, { useEffect } from 'react';
import "./Poster.css";
import { NavLink } from 'react-router-dom';
import { useThemeContext } from '../../contprovider/themeProvider';
import { useLangContext } from '../../contprovider/langProvider';
import { useOpenContext } from '../../contprovider/openProvider';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { langObj } from '../../languages/Poster';
import { currentSeason } from '../../languages/Carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Autoplay, Scrollbar, A11y } from 'swiper/modules';
import * as pics from '../../utils/img'
import { Button } from '@mui/material';


function Poster() {

    const { isDarkTheme } = useThemeContext();
    const { currentLang } = useLangContext();
    const { open } = useOpenContext();
    const storePicArr = Object.values(pics);

    const boxVariant1 = {
        visible: { opacity: 1, x: 0, transition: {duration: 1}},
        hidden: { opacity: 0, x: "-20%" }
    };
    const boxVariant2 = {
        visible: { opacity: 1, x: 0, transition: {duration: 1.5}},
        hidden: { opacity: 0, x: "-20%" }
    };  
    const boxVariant3 = {
        visible: { opacity: 1, x: 0, transition: {duration: 2}},
        hidden: { opacity: 0, x: "-20%" }
    };
    
    ////////

    const control = useAnimation();
    const [ref, inView] = useInView();
    
    useEffect(() => {
        if(inView) {
            control.start("visible")
        }
        
    }, [control, inView])

    /////////
    
    const control2 = useAnimation();
    const [ref2, inView2] = useInView();
    
    useEffect(() => {
        if(inView2) {
            control2.start("visible")
        }
        
    }, [control2, inView2])

    /////////

    const control3 = useAnimation();
    const [ref3, inView3] = useInView();

    useEffect(() => {
        if(inView3) {
            control3.start("visible")
        }
        
    }, [control3, inView3])

    //////////

    const control4 = useAnimation();
    const [ref4, inView4] = useInView();

    useEffect(() => {
        if(inView4) {
            control4.start("visible")
        }
        
    }, [control4, inView4])

    //////////

    return (
    <>
        <div className={`poster-container ${open ? "poster-container-blurred" : ""}`}>
            
            <motion.div 
            className="poster-image-container"
            variants={boxVariant1}
            initial="hidden"
            animate={control}
            ref={ref}
            >
                {/*Set background for the image */}
                
                    <div className="image-holder">
                        <div className="label-box">
                            <p 
                            className="image-label"
                            style={{
                                backgroundColor: isDarkTheme ? "#4d4d4d" : "white",
                                color: isDarkTheme ? "silver" : "black",
                                opacity: 1
                            }}
                            >{currentSeason.summer[currentLang]}</p>
                        </div>
                    </div>
               
            </motion.div>
            
            <div className="poster-misc-container">
                <div className="poster-secondary-image-holder">
                    <motion.div
                    className="poster-inner-image-holder"
                    variants={boxVariant2}
                    initial="hidden"
                    animate={control2}
                    ref={ref2}
                    >
                        <Swiper
                        
                        id="swiper-slider"
                        spaceBetween={30}
                        effect={'fade'}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            disabledClass: '.swiper-button-disabled'
                        }}
                        pagination={{
                          clickable: true,
                          type: "fraction",
                        }}
                        modules={[EffectFade, Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                        className="mySwiper"
                        slidesPerView={ 1 }
                        autoplay={{ delay: 4000 }}
                        centeredSlides={ true }
                        loop={ true }
                        scrollbar={{ draggable: true, hide: true, enabled: false }}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                                spaceBetween: 5
                            }
                        }}
                        >
                            <SwiperSlide>
                                <img 
                                className="secondary-poster one"
                                src={storePicArr[0]}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                className="secondary-poster two"
                                src={storePicArr[1]}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                className="secondary-poster"
                                src={storePicArr[2]}
                                />
                            </SwiperSlide>
                        </Swiper>
                    </motion.div>
                </div>

                <div
                className="poster-text-holder"
                >
                    <motion.div 
                    className="title-holder"
                    variants={boxVariant3}
                    initial="hidden"
                    animate={control3}
                    ref={ref3}
                    >
                        <h2>{langObj.h2[currentLang]} <span>{langObj.span[currentLang]}</span></h2>
                    </motion.div>
                    <motion.div 
                    className="lookup-holder"
                    variants={boxVariant3}
                    initial="hidden"
                    animate={control4}
                    ref={ref4}
                    >

                        <Button id="lookup-button" variant="contained">
                            <NavLink to="/collections" className="lookup-link">{langObj.lookupButton[currentLang]}</NavLink>
                        </Button>
                        
                        <p>
                            {langObj.p[currentLang]}
                            
                        </p>
                        
                    </motion.div>
                </div>
            </div>
        </div>
        <style jsx>
            {`

            .poster-text-holder h2 {
                color: ${isDarkTheme ? "var(--black-theme-font)" : "black"};
            }

            .poster-text-holder .lookup-holder #lookup-button {
                background-color: ${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"};
                color: ${isDarkTheme ? "white" : "black"};
            }

            .lookup-holder .lookup-link {
                color: ${isDarkTheme ? "black" : "white"}; 
            }

            .lookup-holder p {
                color: ${isDarkTheme ? "var(--black-theme-font)" : "black"};
            }

            .poster-secondary-image-holder {
                border-bottom: 1px solid ${isDarkTheme ? "white" : "black"};
            }
            
            `}
        </style>
    </>
        
    )
}

export default Poster;