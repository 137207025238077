import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import { ThemeWrapper } from './contprovider/themeProvider';
import { LangWrapper } from './contprovider/langProvider';
import { OpenWrapper } from './contprovider/openProvider';
import MySlider from './components/collections/Carousel';
/// Import fonts here
import './fonts/Infinita.css';
import './fonts/Raleway.css';
import './fonts/Playfair.css';
import './fonts/Oswald.css';
import './fonts/Lobster.css';
import './fonts/Quicksand.css';
///
/// Import custom components here
import WelcomePage from './components/welcomepage/WelcomePage';
import AboutUs from './components/aboutus/Aboutus';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ExpiredSeason from './components/expired/fall/ExpiredSeason';
import CustomCookieOverlay from './components/cookieconsent/CustomCookie';
/// Import photos
import * as fallPics from './components/collections/img_legacy/fall';
import * as wintPics from './components/collections/img_legacy/winter';
import * as sprPics from './components/collections/img_legacy/spring';
///
import { ToastContainer } from 'react-toastify';
import { useState, useEffect } from 'react'; 
/// Context providers

///
/// Language objects
import { currentSeason } from './languages/Carousel';
///

const WithToastLayout = ({ children }) => (
  <>
    <ToastContainer />
    {children}
  </>
);

const fallP = Object.values(fallPics);
const wintP = Object.values(wintPics);
const sprP = Object.values(sprPics)

function App() {

  return (
    <BrowserRouter>
      <ThemeWrapper>
      <LangWrapper>
      <OpenWrapper>
          <CustomCookieOverlay />
          <Routes>
            <Route path="/" element={ <Header /> }>
            <Route path="/" element={ <Footer /> }>
              <Route path="/" element={<WelcomePage /> } />
              <Route path="/collections" element={ <MySlider />} />
              <Route path="/aboutus" element={ <AboutUs /> } />
              <Route path="/contact" element={ <WithToastLayout><Contact /></WithToastLayout> } />
              <Route path="/collections/spring" element={ <ExpiredSeason images={sprP} season={currentSeason.spring} prev={false} next={"/collections/winter"} /> } />
              <Route path="/collections/winter" element={ <ExpiredSeason images={wintP}  season={currentSeason.winter} prev={"/collections/spring"} next={"/collections/fall"} /> }/>
              <Route path="/collections/fall" element={ <ExpiredSeason images={fallP}  season={currentSeason.fall} prev={"/collections/winter"} next={false}/> } />
            </Route>
            </Route>
          </Routes>
      </OpenWrapper>
      </LangWrapper>
      </ThemeWrapper>
    </BrowserRouter>
  )
}

export default App;
