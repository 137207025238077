import React, { useState, useEffect } from "react";
import "./Contact.css";
import { RiSendPlaneLine } from "react-icons/ri";
import { useThemeContext } from "../../contprovider/themeProvider";
import { useLangContext } from "../../contprovider/langProvider";
import { useOpenContext } from '../../contprovider/openProvider';
import { useFormik } from "formik";
import { PostRequest } from "../../api/Reqests";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { langObj } from "../../languages/Contact";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Button, Snackbar, SnackbarContent, Fade } from '@mui/material';
import Card from "./contact_carousel/Card";
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 
{ 
    Navigation, 
    Pagination, 
    Scrollbar, 
    A11y, 
    Autoplay, 
    EffectCoverflow,
    Keyboard
} 
from 'swiper/modules';
//
//import images
import { google_maps_pic } from "./contact_carousel/img";
import { telephone_pic } from "./contact_carousel/img";
//

function Contact() {

    const { isDarkTheme } = useThemeContext();
    const { currentLang } = useLangContext();
    const { open } = useOpenContext();

    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);

    useEffect(() => {
        document.title = `Contact | Eska C.`;

    }, []);

    const boxVariant1 = {
        visible: { opacity: 1, y: 0, transition: {duration: 0.5}},
        hidden: { opacity: 0, y: "25%" }
    };

    const boxVariant2 = {
        visible: { opacity: 1, transition: {duration: 0.5}},
        hidden: { opacity: 0 }
    };

    /////

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if(inView) {
            control.start("visible")
        }
    }, [control, inView]);

    /////
    /////

    const control2 = useAnimation();
    const [ref2, inView2] = useInView();

    useEffect(() => {
        if(inView2) {
            control2.start("visible")
        }
    }, [control2, inView2]);

    /////
    const handleSuccessOpen = () => setSuccessOpen(true);
    const handleSuccessClose = () => setSuccessOpen(false);

    const handleErrorOpen = () => setErrorOpen(true);
    const handleErrorClose = () => setErrorOpen(false);

    /////

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: ""
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, "Too short!").max(30, "Too long!").required("Required"),
            email: Yup.string().email("Invalid email").required("Email is required!"),
            message: Yup.string().required("Message is required!")
        }),
        onSubmit: async(values, { resetForm }) => {
            const { name, email, message } = values;
            const response = await PostRequest(name, email, message);
            console.log(response);
            if(response) {
                // console.log(response.message);
                if(response.message === "Email sent successfully") {
                    handleSuccessOpen()
                    return resetForm();
                } else {
                    // alert("Failure");
                    return handleErrorOpen();
                }
            } else {
                alert("There was no object present");
            }
        }
    });
    
    return (
        <>
            
            <motion.div 
            className={`contact-container-1 ${open ? "contact-container-blurred" : ""}`}
            variants={boxVariant2}
            initial="hidden"
            animate={control2}
            ref={ref2}
            >
                {/* Success Snackbar */}
                <Snackbar
                open={successOpen}
                onClose={handleSuccessClose}
                id="notific-success"
                TransitionComponent={Fade}
                transitionDuration={2500}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}

                >
                    <SnackbarContent
                    message="Message sent!"
                    id="notific-success-content"
                    />
                </Snackbar>
                {/* Failure Snackbar */}
                <Snackbar
                open={errorOpen}
                onClose={handleErrorClose}
                id="notific-error"
                TransitionComponent={Fade}
                transitionDuration={2500}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                >
                    <SnackbarContent
                    message="Message could not be sent!"
                    id="notific-error-content"
                    />
                </Snackbar>

                <form 
                className="contact-inner-container"
                onSubmit={formik.handleSubmit}
                >
                    <div className="contact-inner-container-1">
                        <div className="details-form">
                            <div className="name-form-container">
                                <div>
                                    <label htmlFor="name">{langObj.name[currentLang]}</label>
                                    <input 
                                    id="name"
                                    name="name" 
                                    placeholder="Joe Doe" 
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.errors.name && formik.touched.name ? "input-error" : ""}
                                    />
                                    
                                </div>
                                
                            </div>
                            <div className="email-form-container">
                                <div>
                                    <label htmlFor="email">{langObj.email[currentLang]}</label>
                                    <input 
                                    id="email"
                                    name="email" 
                                    placeholder="Joe.doe@whatever.com" 
                                    type="text"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.errors.email && formik.touched.email ? "input-error" : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="message-form">
                            <label htmlFor="text">{langObj.message[currentLang]}</label>
                            <textarea 
                            id="message"
                            name="message"
                            className={`text-input ${formik.errors.message && formik.touched.message ? "input-error" : ""}`}
                            placeholder={langObj.messagePlaceholder[currentLang]}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="submit-button-container">
                        <Button 
                        id="submit-button"
                        variant="contained"
                        type="submit"
                        >
                            <p>{langObj.submit[currentLang]}</p>
                            <RiSendPlaneLine />
                        </Button>
                    </div>
                </form>
                <motion.div 
                variants={boxVariant1}
                initial="hidden"
                animate={control}
                ref={ref}
                className="contact-container-2">
                    <Swiper
                    style={{
                        "--swiper-pagination-color": isDarkTheme ? "rgba(255, 215, 0, 1)" : "black",
                    }}
                    className="contact_swiper"
                    slidesPerView={2}
                    modules={[Navigation, Scrollbar, A11y, Autoplay, EffectCoverflow, Keyboard]}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        
                    }}
                    autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
                    loop={ true }
                    breakpoints={{
                        1025: {
                            slidesPerView: 2,
                            spaceBetween: 0
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        450: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        300: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        }
                     }}   
                    >
                        <SwiperSlide
                        >
                            <Card 
                            image={google_maps_pic}
                            title={langObj.card1}
                            button={langObj.card5}
                            text={langObj.navigateText}
                            />
                        </SwiperSlide>
                        <SwiperSlide
                        >
                            <Card 
                            image={telephone_pic}
                            title={langObj.card2}
                            button={langObj.card4}
                            text={langObj.contactText}
                            />
                        </SwiperSlide>
                        <SwiperSlide
                        >
                            <Card 
                            image={google_maps_pic}
                            title={langObj.card3}
                            button={langObj.card5}
                            text={langObj.navigateText}
                            />
                            
                        </SwiperSlide>
                    </Swiper>
                </motion.div>
            </motion.div>
            <style jsx>
                {`

                .details-form label {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .message-form label {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .details-form input,
                .message-form .text-input {
                    background-color: ${isDarkTheme ? "rgb(160, 160, 160)" : "rgb(235, 235, 235)"};
                }

                .contact-inner-container #submit-button {
                    background-color: ${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"};
                    color: ${isDarkTheme ? "black" : "white"};
                }

                .location-holder .map-icon,
                .telephone-holder .phone-icon {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .text-holder .text,
                .phone-number-holder .text {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .contact-anchor {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .text-holder .contact-address {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .contact-anchor:visited {
                    color: ${isDarkTheme ? "aqua" : "purple"};
                }
                
                .contact-container-2 .location-holder,
                .contact-container-2 .telephone-holder {
                    border: 0.1px solid ${isDarkTheme ? "grey" : "rgb(224, 224, 224)"};
                    box-shadow: ${isDarkTheme ? "5px 5px 12px 0 rgba(255, 255, 255, 0.2)" : "5px 5px 12px 0 rgba(0, 0, 0, 0.2)"};
                }

                .contact-container-2 .location-holder:hover,
                .contact-container-2 .telephone-holder:hover {
                    box-shadow: ${isDarkTheme ? "10px 10px 23px 0 rgba(255, 255, 255, 0.2)" : "10px 10px 23px 0 rgba(0, 0, 0, 0.2)"};
                }

                `}
            </style>
        </>
    )
}

export default Contact;