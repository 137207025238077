export const langObj = {
    name: {
        "hu": "Név",
        "en": "Your name"
    },

    email: {
        "hu": "Email cím",
        "en": "Your email"
    },

    message: {
        "hu": "Üzenet",
        "en": "Message"
    },

    messagePlaceholder: {
        "hu": "Miben segíthetünk?",
        "en": "How may we be of service?"
    },

    submit: {
        "hu": "KÜLDÉS",
        "en": "SUBMIT"
    },

    card1: {
        "hu": "Látogasson meg minket",
        "en": "Visit us"
    },

    card2: {
        "hu": "Hívjon minket",
        "en": "Call us"
    },

    card3: {
        "hu": "Keresse fel másik üzletünk",
        "en": "Visit our other store"
    },

    card4: {
        "hu": "Hívás",
        "en": "Call"
    },

    card5: {
        "hu": "Útvonal",
        "en": "Route"
    },

    contactText: {
        "hu": "Hívjon minket hétköznap 08:00 17:00-ig, szombaton 08:00-tól 12:00-ig.",
        "en": "Call os on weekdays from 08:00 until 17:00, on Saturday from 08:00 until 12:00."
    },

    navigateText: {
        "hu": "Tervezzen útvonalat üzletünkhöz",
        "en": "Plan a route to our store"
    }
};