export const langObj = {
    title: {
        "hu": "ESKA: KIK IS VAGYUNK",
        "en": "ESKA: WHO WE ARE"
    },

    capitalize: {
        "hu": "Kisméretű ruhatár egy rohanó világban. Ruhák, amik mindenkit öltöztetnek kortól vagy termettől függetlenül. Ízléses, időtlen darabokat kínálunk, nőktől nőknek, megihletődve azok mindennapi nehézségeitől és problémáitól. Készletünk darabjai fenntarthatók és tartósak.",
        "en": "Capsule wardrobe for a busy life. Clothes that will suit anybody regardless of age or figure. Subtle, timeless products, delivered by women for women. Taking inspiration from women's everyday needs and struggles, we aim to provide sustainable products, that you will wear for years to come."
    },

    creationTime: {
        "hu": "2000-ben alapítva",
        "en": "Est. in 2000"
    },

    creationText: {
        "hu": "2000-ben alapított első üzletünk sikerein felbátorodva nyitottuk meg Eska C. boltunkat Rajkán 2020-ban, hogy még letisztultabb formában láthassuk el ügyfeleinket termékeinkkel.",
        "en": "Encouraged by our first store's success established in 2000, we opened our Eska C. shop in Rajka in 2020, to supply clothes to our customers in a more refined manner."   
    },
    
    bottomText: {
        "hu": "Barátságos és készséges munkatársainkkal lelkesen üdvözöljük önt üzletünkben. Részletekbe és összeállításba menő gondosság várja önt az Eska-nál, egyáni igényekre és kívánságokra szabva.",
        "en": "With our friendly and forthcoming staff, we are eager to welcome you at our store. Attention to detail and composition awaits you at Eska, tailored to your unique needs and wishes."
    }
}