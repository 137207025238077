import React, { useEffect } from "react";
import "./Aboutus.css";
import { useThemeContext } from "../../contprovider/themeProvider";
import { useLangContext } from "../../contprovider/langProvider";
import { useOpenContext } from "../../contprovider/openProvider";
import { langObj } from "../../languages/Aboutus";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import storeFront from "../../utils/img/storefront.webp";
import monroe from "../../utils/img/monroe.webp"

function AboutUs() {

    const { isDarkTheme } = useThemeContext();
    const { currentLang } = useLangContext();
    const { open } = useOpenContext();

    useEffect(() => {
        document.title = `About Us | Eska C.`;

    }, []);

    const boxVariant = {
        visible: { opacity: 1, x: 0, transition: {duration: 2}},
        hidden: { opacity: 0, x: "-20%" }
    };

    const boxVariantMount = {
        visible: { opacity: 1, transition: {duration: 2}},
        hidden: { opacity: 0, }
    };

    //////

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if(inView) {
            control.start("visible")

        }
    }, [control, inView]);

    ////////

    const control2 = useAnimation();
    const [ref2, inView2] = useInView();

    useEffect(() => {
        if(inView2) {
            control2.start("visible")

        }
    }, [control2, inView2]);

    ///////

    const control3 = useAnimation();
    const [ref3, inView3] = useInView();

    useEffect(() => {
        if(inView3) {
            control3.start("visible")

        }
    }, [control3, inView3]);

    ///////

    const control4 = useAnimation();
    const [ref4, inView4] = useInView();

    useEffect(() => {
        if(inView4) {
            control4.start("visible")

        }
    }, [control4, inView4]);

    ///////

    const control5 = useAnimation();
    const [ref5, inView5] = useInView();

    useEffect(() => {
        if(inView5) {
            control5.start("visible")
        }
    }, [control5, inView5]);

    ///////

    return (

        <>
            <motion.div 
            className={`${open ? "about-us-blurred" : "about-us-not-blurred"}`}
            variants={boxVariantMount}
            initial="hidden"
            animate={control5}
            ref={ref5}
            >
            <div 
            className="about-us-container"
            >
                <div className="about-us-inner-container">
                    <div className="about-us-image-holder">
                        <img
                        className="about-us-image"
                        src={storeFront}
                        loading="lazy"
                        alt="storefront"
                        />
                    </div>
                    <div className="text-holder">
                        <motion.div 
                        className="title-holder"
                        variants={boxVariant}
                        initial="hidden"
                        animate={control}
                        ref={ref}
                        >
                            <h1>{langObj.title[currentLang]}</h1>
                        </motion.div>
                        <motion.div 
                        className="text-area"
                        variants={boxVariant}
                        initial="hidden"
                        animate={control2}
                        ref={ref2}
                        >
                            <p>
                                <span className="capitalize">
                                    {langObj.capitalize[currentLang]}
                                </span>
                            </p>
                        </motion.div>

                        <div className="creation-card">
                            <div className="creation-photo-container">
                                <img 
                                className="creation-photo"
                                src={monroe}
                                alt="shelves with clothes"
                                />
                            </div>
                            <motion.div 
                            className="creation-text-holder"
                            variants={boxVariant}
                            initial="hidden"
                            animate={control3}
                            ref={ref3}
                            >
                                <h3>{langObj.creationTime[currentLang]}</h3>
                                <p>
                                    {langObj.creationText[currentLang]}
                                </p>
                            </motion.div>    
                        </div>

                        <motion.div 
                        className="bottom-text-holder"
                        variants={boxVariant}
                        initial="hidden"
                        animate={control4}
                        ref={ref4}
                        >
                            <p>
                                {langObj.bottomText[currentLang]}
                            </p>
                        </motion.div>

                    </div>
                </div>
            </div>
            </motion.div>
            <style jsx>
                {`
                h1,
                h2,
                h3,
                h4,
                p {
                    color: ${isDarkTheme ? "var(--black-theme-font)" : "black"};
                }

                `}
            </style>
        </>

    )
}

export default AboutUs;