
const API_ENDPOINT = "https://api.eskafashion.com";

export const PostRequest = async(name, email, message) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/contact`, {
            method: "POST",
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
        const responseData = await response.json();
        return responseData;
    } catch(e) {
        console.log(e.message);
        return { error: "An error occured while sending the request" };
    }

}