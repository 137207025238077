export const langObj = {
    h2: {
        "hu": "Kínált ruháink ",
        "en": "We offer clothes that will"
        
    },

    span: {
        "hu": "kihangsúlyozzák vonásait, amivel még magabiztosabban érezheti magát.",
        "en": "emphasize your curves and make you even more confident"
    },

    p: {
        "hu": "Ismerkedjen meg évszakonként megjelenő kollekcióinkkal, hogy megtalálja a leginkább önhöz illő darabot.",
        "en": "Get acquanted with our seasonal new arrivals, in order to obtain the ones, that suit your needs the most."
    },

    lookupButton: {
        "hu": "KOLLEKCIÓK",
        "en": "SHOW SETS"
    }
}