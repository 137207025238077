import React, { useEffect, useState } from "react";
import "./Intro.css";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useThemeContext } from "../../contprovider/themeProvider";
import { useLangContext } from "../../contprovider/langProvider";
import { useOpenContext } from "../../contprovider/openProvider";
import { NavLink } from "react-router-dom";
import { langObj } from "../../languages/Intro"; 
import { Button } from '@mui/material';
import Marquee from "react-fast-marquee";


function Intro() {

    const { isDarkTheme } = useThemeContext();
    const { currentLang } = useLangContext();
    const { open } = useOpenContext();

    const [textToType, setTextToType] = useState(langObj.imageHolderOne[currentLang]);
    const [typedText, setTypedText] = useState("");

    const [scrollPositionLocal, setScrollPositionLocal] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const typingSpeed = 50;

    const boxVariant1 = {
        visible: { opacity: 1, x: 0, transition: {duration: 2}},
        hidden: { opacity: 0, x: "-20%" }
    };

    const boxVariant2 = {
        visible: { opacity: 1, y: 0, transition: {duration: 2}},
        hidden: { opacity: 0, y: "-20%" }
    };

    const styles = {
        box: {
            display: "flex",
            justifyContent: "space-around",   
            alignItems: "cener",
            
            // border: "1px solid red",
        },
        title: {
            fontFamily: "Oswald",
            
            color: isDarkTheme ? "silver" : "black"
        }
    };

    
    useEffect(() => {
        const updatePosition = () => {
            setScrollPositionLocal(window.scrollY);
        }
        window.addEventListener("scroll", updatePosition);
        
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    ///////

    const control = useAnimation();
    const [ref, inView] = useInView();
    
    useEffect(() => {
        if(inView) {
            control.start("visible")
        }
    }, [control, inView]);

    ///////
    
    const controlContact = useAnimation();
    const [refContact, inViewContact] = useInView();

    useEffect(() => {
        if(inViewContact) {
            controlContact.start("visible")
        }
    }, [controlContact, inViewContact]);

    ////////

    useEffect(() => {
        setTextToType(langObj.imageHolderOne[currentLang]);
        setTypedText(""); 
        setCurrentIndex(0);
    }, [currentLang]);

    useEffect(() => {
        if(currentIndex < textToType.length) {

            const typeTimeout = setTimeout(() => {
                setTypedText(prevText => prevText + textToType[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);

            }, typingSpeed)

            return () => clearInterval(typeTimeout);
        }

    }, [currentIndex]);

    return (
        <>
        <div className={`intro-superior-container ${open ? "intro-superior-blurred" : ""}`}>
        <div 
        
        className="intro-container"
        >
            <div className="intro-inner-container quote-cont">
                    {/* <motion.h1 
                    className="quote"
                    variants={boxVariant1}
                    initial="hidden"
                    animate={control}
                    ref={ref}
                    >{langObj.quote[currentLang]}</motion.h1>
                    <div className="author-holder">
                        <motion.p
                        className="author"
                        variants={boxVariant1}
                        initial="hidden"
                        animate={control}
                        ref={ref}
                        >Wolfgang Joop</motion.p>
                    </div> */}
                    <Marquee 
                    gradient={true}
                    gradientColor={isDarkTheme ? "#4d4d4d" : "white"}
                    autoFill={true}
                    loop={0}
                    className="poster-marquee"
                    >
                        <h3  style={styles.title} className="marquee-title">{currentLang === "hu" ? "# HITELESSÉG" : "# AUTHENTICITY"}</h3>
                        <h3 style={styles.title} className="marquee-title">{currentLang === "hu" ? "# MEGBÍZHATÓSÁG" : "# RELIABILITY"}</h3>
                        <h3 style={styles.title} className="marquee-title">{currentLang === "hu" ? "# ÜGYFÉLKÖZPONTÚSÁG" : "# PERSONALIZATION"}</h3>
                    </Marquee>

            </div>
        </div>
        <div className="offer-container">
            <div 
            className="offer-holder"
            style={{ top: 200 -scrollPositionLocal * 0.25 + "px" }}
            >
                <div className="offer-image-holder one">
                    <p>
                        {typedText}
                    </p>
                </div>
                
            </div>
        </div>
        <motion.div 
        className="contact-container"
        variants={boxVariant1}
        initial="hidden"
        animate={controlContact}
        ref={refContact}
        >
            <div className="contact-holder">
                <h2 className="fulfill">{langObj.fulfill[currentLang]}</h2>
                <img 
                src="https://images.pexels.com/photos/3965543/pexels-photo-3965543.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="yarn, clothes and a plant"
                className="image-with-yarn"
                />
                <img
                src="https://images.pexels.com/photos/3965543/pexels-photo-3965543.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="yarn, clothes and a plant"
                className="image-with-yarn reflection"
                />
                <Button id="contact-button" variant="contained">
                    <NavLink to="/contact" className="contact-link navlink">{langObj.contactButton[currentLang]}</NavLink>
                </Button>
                
            </div>
        </motion.div>
        </div>
        <style jsx>
            {`
            

            .intro-inner-container .quote,
            .intro-inner-container .author {
                color: ${isDarkTheme ? "var(--black-theme-font)" : "black"};
            }

            .contact-holder #contact-button {

                background-color: ${isDarkTheme ? "rgba(255, 215, 0, 1)" : "black"};
                color: ${isDarkTheme ? "black" : "white"};
            }
            
            .contact-holder .contact-link {
                color: ${isDarkTheme ? "black" : "white"};
                text-decoration: none;
            }

            .contact-holder .fulfill {
                color: ${isDarkTheme ? "var(--black-theme-font)" : "black"};
            }

            
            `}
        </style>
        </>
    )
}

export default Intro;