import React, { useEffect } from "react";
import Poster from "../poster/Poster";
import Intro from "../intro/Intro";
//import "./WelcomePage.css";

function WelcomePage() {

    useEffect(() => {
        document.title = `Home | Eska C.`;

    }, []);

    return (

        <div>
            <div>
                <Poster />
            </div>
            <Intro />
        </div>
    
    )
};


export default WelcomePage;